// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDc8EMvRu2RdSGBRtS6ne3mn7rNGt8u7aY",
  authDomain: "weseleasiikrisa.firebaseapp.com",
  projectId: "weseleasiikrisa",
  storageBucket: "weseleasiikrisa.appspot.com",
  messagingSenderId: "937129495258",
  appId: "1:937129495258:web:4db6da71afb86c6a1e73e3",
  measurementId: "G-76RPNCSREF"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);

export const getToken2 = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BFkmIjP8wCqXtHnjUmM4XYg8Nz7dd25lunDrV66xzhqO-A5dBdAUlSVbpZ1Pvyduh0Z8NfidI2Ijun7Mfa5EYYY'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        if (setTokenFound) {
          let data = {'token': currentToken};
          console.log(data);
            fetch("https://api.weseleasiikrisa.ct8.pl/api/notification/register", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json"
                },
            });
          if(localStorage.getItem('fcm_token') === null) {
            localStorage.setItem('fcm_token', currentToken);
          } else {
            if (localStorage.getItem('fcm_token') !== currentToken) {
              let data = {'token': localStorage.getItem('fcm_token')};
              console.log("Wyrejestrowanie złego tokena!");
              fetch("https://api.weseleasiikrisa.ct8.pl/api/notification/unregister", {
                method: "DELETE",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json"
                },
              })
              .then(localStorage.setItem('fcm_token', currentToken));
            }
          }
    }
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      if (localStorage.getItem('fcm_token') !== null) {
        let data = {'token': localStorage.getItem('fcm_token')};
              console.log("Wyrejestrowanie złego tokena!");
              fetch("https://api.weseleasiikrisa.ct8.pl/api/notification/unregister", {
                method: "DELETE",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json"
                },
              })
              .then(localStorage.removeItem('fcm_token'));
      }
      // catch error while creating client token
    });
  }
  
  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });