import React from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { LoginPage } from './components/loginPage';
import { MainPage } from './components/mainPage';
import { GuestBookPage } from './components/guestBook';
import { RecoveryPage } from './components/recoveryPage';
import './firebase'
import {Navigate} from 'react-router-dom';

/*function isBlocked(month, day, api) {
  if(month >= 6 && day > 1) {
    return <Route path="/form" element={<h1 style={{position: 'absolute', top: '50%'}}>Zakończono zbieranie potwierdzeń przybycia. Dziękujemy!</h1>} />
  } else {
    return <Route path="/form" element={<AcceptForm api={api}/>} />
  }
}*/

function App() {
  //let api = "http://localhost/wesele-api/public/api/"
  let api = "https://api.weseleasiikrisa.ct8.pl/api/"
  /*const date = new Date();
  const month = date.getMonth();
  const day = date.getDate();
  console.log(day + " " + month);*/
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage api={api}/>} />
          <Route path="/form" element={<Navigate to="/app" replace={true}/>} />
          <Route path="/status" element={<Navigate to="/app" replace={true}/>} />
          <Route path="/login" element={<LoginPage api={api}/>}/>
          <Route path="/app" element={<MainPage api={api}/>}/>
          <Route path="/guestbook" element={<GuestBookPage api={api}/>}/>
          <Route path="/recovery/:code" element={<RecoveryPage api={api}/>}/>
          <Route path="/**" element={<Navigate to="/app" replace={true}/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
