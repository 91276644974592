import React from 'react';
import {useParams} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

function returnGoodForm(data) {
    if (data.multi_invitation === 0) {
        return data.name + " " + data.surname;
    } else {
        if (checkIfAccompanying(data) === 1) {
            return data.name + " " + data.surname + " z osobą towarzyszącą";
        } else {
            if (checkIfIsTheSameSurname(data) === 1) {
                let info = data.name
                let length = data.multi.length;
                let i = 1;
                data.multi.forEach(guest => {
                    if (i === length) {
                        info += " i " + guest.name;
                    } else {
                        info += ", " + guest.name;
                    }
                })
                info += " " + data.surname;
                return info
            } else {
                let info = data.name + " " + data.surname;
                let length = data.multi.length;
                let i = 1;
                data.multi.forEach(guest => {
                    if (i === length) {
                        info += " i " + guest.name + " " + guest.surname;
                    } else {
                        info += ", " + guest.name + " " + guest.surname;
                    }
                })
                return info;
            }
        }
    }
}

function checkIfAccompanying (data) {
    let isAccompanying = 0;
    data.multi.forEach(guest => {
        if (guest.name === "Osoba" && guest.surname === "towarzysząca") {
            isAccompanying = 1;
        }
    });
    return isAccompanying;
}

function checkIfIsTheSameSurname (data) {
    let isTheSame = 1;
    data.multi.forEach(guest => {
        if (guest.surname !== data.surname) {
            isTheSame = 0;
        }
    });
    return isTheSame;
}

export function RecoveryPage(props) {
    let {code} = useParams();

    let [guestData, setGuestData] = React.useState([]);
    let [isLoaded, setIsLoaded] = React.useState(false);

    const handleStat = (data) => {
        fetch(props.api + "loginstats", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        })
         .then(res => res.json())
         .then(response => {
            localStorage.setItem("stat_data", response);
            setIsLoaded(true);
         })
    }

    React.useEffect(() => {
        if(!isLoaded) {
            if(localStorage.getItem('name') === null) {
                fetch(props.api + "recovery/use/" + code, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                 .then(res => res.json())
                 .then(response => {
                    const loginData = {
                        name: response.data.name,
                        surname: response.data.surname,
                        password: response.data.password
                    }
                    const loginDataJSON = JSON.stringify(loginData);
                    fetch(props.api + "loginguest", {
                        method: "POST",
                        body: loginDataJSON,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(res => res.json())
                    .then(response => {
                        if (response.status === "error") {
                            alert("Kod odzyskiwania jest niepoprawny lub wygasł. Nie można pobrać danych odzyskiwania. Zeskanuj kod QR od Administratora ponownie.");
                            window.location.assign("/login");
                        } else {
                            let data = response.data;
                            data.guest_id = data.id;
                            handleStat(data);
                            setGuestData(response.data);
                        }
                    })
                 })
            } else {
                alert("Jesteś już zalogowany! Jeżeli pobierane są złe dane, wyloguj się i dopiero dokonaj odzyskania hasła.");
                window.location.assign("/app");
            }
        } 
    });

    if (isLoaded) {
        return(
            <Container fixed>
                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '85vw',
                    height: '100vh',
                    alignItems: 'center'
                }}
                >
                    <h1>Uzyskanie dostępu z pomocą Administratora</h1>
                    <p>Próbujesz uzyskać dostęp do aplikacji weselnej jako {returnGoodForm(guestData)} z pomocą Administratora.</p>
                    <p>Jeżeli dane są poprawne - kliknij przycisk poniżej.</p>
                    <Button variant="contained" onClick={() => {
                        localStorage.setItem('name', guestData.name);
                        localStorage.setItem('surname', guestData.surname);
                        localStorage.setItem('password', guestData.password);
                        window.location.assign("/app");
                    }}>Przejdź do aplikacji</Button>
                    <p>Jeżeli to nie jesteś Ty - otrzymałeś/aś zły kod. <b>Wyjdź z tej strony</b> i poproś o nowy kod.</p>
                </Box>
            </Container>
        );
    } else {
        return (
            <Container fixed>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
                marginTop: '50px',
                marginBottom: '50px',
                alignItems: 'center'
            }}
            >
                <CircularProgress />
                <p><center>Ładujemy dane, poczekaj chwilę...</center></p>
            </Box>
        </Container>
        );
    }
}