import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {Formik, Form, Field} from 'formik';
import Snackbar from '@mui/material/Snackbar';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';



export function GuestBookPage(props) {
    const [guestBookAddContent, setGuestBookAddContent] = React.useState(false);
    const [guestBookContent, setGuestBookContent] = React.useState(false);
    const [guestBookContentInserted, setGuestBookContentInserted] = React.useState(false);
    const [guestBookAddContentWorking, setGuestBookAddContentWorking] = React.useState(false);
    const [guestBookLoadWorking, setGuestBookLoadWorking] = React.useState(false);
    const [guestBookData, setGuestBookData] = React.useState([]);
    const [lastPage, setLastPage] = React.useState(1);
    const [guestId, setGuestId] = React.useState(0);
    const guestName = localStorage.getItem('name');
    const guestSurname = localStorage.getItem('surname');
    const guestPassword = localStorage.getItem('password');

    const loginData = {
        name: guestName,
        surname: guestSurname,
        password: guestPassword
    }
    const loginDataJSON = JSON.stringify(loginData);

    React.useEffect(() => {
        if (guestId === 0) {
            let data = localStorage.getItem("stat_data");
            if (data === null) {
                localStorage.removeItem('name');
                localStorage.removeItem('surname');
                localStorage.removeItem('password');
                window.location.href = "/login";
            }
            fetch(props.api + "loginguest", {
                method: "POST",
                body: loginDataJSON,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(res => res.json())
            .then(response => {
                if (response.data.name === 'error') {
                    localStorage.removeItem('name');
                    localStorage.removeItem('surname');
                    localStorage.removeItem('password');
                    window.location.href = "/login";
                } else {
                    setGuestId(response.data.id);
                }
                },
                error => {
                    console.log(error);
                    alert ("Wystąpił błąd w kontakcie z serwerem. Odśwież stronę, a jeżeli problem się powtórzy, zgłoś to Parze Młodej.");
                }
            );
        }
    });

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [page, setPage] = React.useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
        downloadData(value);
    };

    const downloadData = (page) => {
        setGuestBookLoadWorking(true);
        fetch(props.api + "guestbook/load?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
         .then(res => res.json())
         .then(response => {
            setLastPage(response.last_page);
            setGuestBookData(response.data);
            setGuestBookLoadWorking(false);
            setGuestBookContent(true);
            setGuestBookAddContent(false);
         },
        error => {
            console.log(error);
            alert("Wystąpił błąd ładowania wpisów księgi gości. Księga zostanie odświeżona za kilka sekund. Jeżeli problem nie ustąpi, zgłoś to administratorowi");
            sleep(5000).then(() => window.location.reload());
        })
    }

    function SelectScreen () {
        if (guestBookLoadWorking) {
            return (
                <>
                    <CircularProgress />
                    <p style={{fontFamily: 'Aboreto'}}><center>Ładujemy dane, poczekaj chwilę...</center></p>
                </>
            )
        } else if (!guestBookAddContent && !guestBookContent && !guestBookLoadWorking) {
            return EntryScreen();
        } else if (guestBookAddContent) {
            let signDownloaded = localStorage.getItem('guestInfo');
            let signToSubstitute = signDownloaded.replace("Jesteście zalogowani jako ", "");
            return (
                <>
                    <h1 style={{fontFamily: "Pinyon Script", fontSize: 50}}>Złóż życzenia</h1>
                    <Button variant="outlined" onClick={() => {
                            downloadData(1);
                            }}>Przeczytaj zawartość</Button>
                    <br/>
                    <Box sx={{gap: 2, display: 'flex', flexDirection: 'column'}}>
                        <Formik
                        initialValues={{
                            sign: signToSubstitute,
                            entry_data: ""
                        }}
                        onSubmit={(values) => {
                            console.log(values);
                            values.guest_id = guestId;
                            setGuestBookAddContentWorking(true);
                            fetch(props.api + "guestbook/save", {
                                method: "POST",
                                body: JSON.stringify(values),
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                             .then(res => res.json())
                             .then(response => {
                                if (response.data.sign === values.sign) {
                                    setGuestBookContentInserted(true);
                                    sleep(5000).then(() => { 
                                        downloadData(1);

                                    });
                                } else {
                                    alert("Wystąpił błąd! Spróbuj ponownie.");
                                    setGuestBookAddContentWorking(false);
                                }
                             },
                             error => {
                                alert("Wystąpił błąd! Spróbuj ponownie!");
                                setGuestBookAddContentWorking(false);
                                console.log(error);
                            });
                        }}
                        >
                            <Form style={{gap: 2, display: 'flex', flexDirection: 'column'}}>
                                <Field as={TextField}
                                    id="entry_data"
                                    name="entry_data"
                                    label="Treść życzeń"
                                    multiline
                                    rows={4}
                                    required
                                /><br/>
                                <Field as={TextField} required id="sign" name="sign"label="Podpis gości" variant="outlined"/><br/>
                                <Button type="submit" variant="contained" disabled={guestBookAddContentWorking}>Zapisz</Button>
                            </Form>
                        </Formik>
                        
                    </Box>
                </>
            );
        } else {
            if (guestBookLoadWorking) {
                return (
                    <>
                        <CircularProgress />
                        <p style={{fontFamily: 'Aboreto'}}><center>Ładujemy dane, poczekaj chwilę...</center></p>
                    </>
                )
            } else {
                return (
                    <>
                        <h1 style={{fontFamily: "Pinyon Script", fontSize: 50, textAlign: 'center'}}>Wasze życzenia</h1>
                        <Button variant="contained" onClick={() => {
                            setGuestBookAddContent(true);
                            setGuestBookContent(false);
                            }}>Złóż życzenia</Button>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {guestBookData.map(entry => (
                                <>
                                    <ListItem alignItems="flex-start" key={entry.id}>
                                        <ListItemText
                                        primary={
                                            <Typography sx={{fontFamily: 'Aboreto', fontSize: 25}}>
                                                {entry.entry_data}
                                            </Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline', fontFamily: 'Pinyon Script', textAlign: 'right', float: 'right' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {entry.sign}
                                            </Typography>
                                            </React.Fragment>
                                        }
                                        />
                                </ListItem>
                            <Divider variant="inset" component="li" />
                            </>
                            ))}
                        </List>
                        <Pagination count={lastPage} color="primary" page={page} onChange={handlePageChange}/>
                    </>

                )
            }
        }
    }

    function EntryScreen() {
        return (
            <>
                <h1 style={{fontFamily: "Pinyon Script", fontSize: 50}}>Księga gości</h1>
                    <p style={{fontFamily: "Aboreto", fontSize: 18}}>WESELNYCH</p>
                    <Box sx={{gap: 2, display: 'flex', flexDirection: 'column'}}>
                        <Button variant="outlined" onClick={() => {
                            downloadData(1);
                            }}>Przeczytaj zawartość</Button>
                        <Button variant="contained" onClick={() => {setGuestBookAddContent(true)}}>Złóż życzenia</Button>
                    </Box>
            </>
        )
    }

    function closeSnackbar() {
        setGuestBookContentInserted(false)
    }

    return (
        <Container sx={{backgroundImage: 'url("/background-old.jpg")', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh'}}>
            <Box display="flex" alignItems="center" sx={{padding: 1, flexDirection: 'column', backgroundColor: 'white', width: '85vw', height: '85vh', justifyContent: 'center', verticalAlign: 'middle', alignItems: 'center', alignContent: 'center', marginLeft: '20px', marginRight: '20px'}}>
                {SelectScreen()}
            </Box>
            <Snackbar
                open={guestBookContentInserted}
                autoHideDuration={6000}
                onClose={closeSnackbar}
                message="Wpisano życzenia do księgi. Przekierowuję do zawartości"
            />
        </Container>
    );
}