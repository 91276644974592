import React, {useState} from 'react';
import {Formik, Form, Field} from 'formik';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Navigate, useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function LoginPage (props) {
    const [buttonText, setButtonText] = useState("Zaloguj");
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleClose3 = () => {
        setOpen2(false);
        setOpen(true);
    };

    const handleStat = (data) => {
        fetch(props.api + "loginstats", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        })
         .then(res => res.json())
         .then(response => {
            localStorage.setItem("stat_data", response);
            navigate('/app');
         })
    }
    const navigate = useNavigate();

    if(localStorage.getItem('name') !== null) {
        return (<><Navigate to="/app" replace={true}/></>);
    }

    
    return (
        <Container fixed>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '50px',
                marginBottom: '50px',
                width: '85vw',
                height: '100vh',
                alignItems: 'center'
            }}
            >
                <h1>Zaloguj się</h1>
                <p>Podaj imię i nazwisko, Twoje lub jednej z osób ujętej w zaproszeniu, które otrzymałeś/aś, oraz hasło.</p>
                <Formik
                    initialValues={{
                        name: "",
                        surname: "",
                        password: ""
                    }}
                    onSubmit={(values) => {
                        setButtonText("Wyszukiwanie...");
                        let guestData = JSON.stringify(values);
                        fetch(props.api + "loginguest", {
                            method: "POST",
                            body: guestData,
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                         .then(res => res.json())
                         .then(response => {
                            if (response.data.name === 'error') {
                                setButtonText("Zaloguj");
                                handleClickOpen2();
                            } else {
                                localStorage.setItem('name', response.data.name);
                                localStorage.setItem('surname', response.data.surname);
                                localStorage.setItem('password', response.data.password);
                                let data = response.data;
                                data.guest_id = data.id;
                                handleStat(data);
                            }
                         },
                         error => {
                            console.log(error);
                            alert ("Wystąpił błąd! Spróbuj ponownie, a jeżeli nadal będzie występował, skontaktuj się z Parą Młodą.");
                            setButtonText("Zaloguj");
                         });
                    }}
                >
                    <Form style={{display: 'flex', gap: '20px', justifyContent: 'center', flexDirection: 'column',}}>
                        <Field as={TextField} name="name" id="name" label="Imię" variant="outlined" style={{width: '300px'}} required/>
                        <Field as={TextField} name="surname" id="surname" label="Nazwisko" variant="outlined" style={{width: '300px'}} required/>
                        <Field as={TextField} type="password" name="password" id="password" label="Hasło" variant="outlined" style={{width: '300px'}} required/>
                        
                        <Button type="submit" variant="contained" style={{width: '300px'}} disabled={buttonText === "Wyszukiwanie"}>{buttonText}</Button>
                    </Form>
                </Formik>
                <Button variant="text" onClick={handleClickOpen}>
                    Potrzebuję pomocy
                </Button>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Pomoc w logowaniu"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <i>Przewijaj komunikat do końca, by przeczytać całość</i>
                        <br/><br/>
                        <b>Metoda logowania jest identyczna do stosowanej w czasie zbierania odpowiedzi na zaproszenia.</b>
                        <br/><br/>
                        Celem zalogowania się do systemu należy podać imię i nazwisko jednej z osób ujętych w zaproszeniu na wesele. Nie należy podawać imion i nazwisk wszystkich osób.
                        <br/>Nie można się zalogować do systemu danymi osoby towarzyszącej niewpisanej z imienia i nazwiska do zaproszenia.
                        <br/><br/>
                        Następnie, do ostatniego pola formularza należy wprowadzić hasło napisane ręcznie na niezadrukowanej stronie karteczki z kodem QR do aplikacji. Po uzupełnieniu wszystkich pól można użyć przycisku Zaloguj celem podjęcia próby uzyskania dostępu do systemu.
                        <br/><br/>
                        <b style={{'color': 'red'}}>Zaproszenia, na które goście przesłali odpowiedź odmowną, mogły zostać usunięte z systemu.</b>
                        <br/>Za pomocą danych na nich zawartych nie można uzyskać dostępu do systemu.
                        <br/><br/>
                        Celem zadania pytania na temat logowania bądź zgłoszenia problemu prosimy kontaktować się z Parą Młodą.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Zamknij</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={open2}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose2}
                    aria-describedby="alert-dialog-slide-description2"
                >
                    <DialogTitle>{"Pomoc w logowaniu"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description2">
                        <b>Nie znaleziono Ciebie na żadnym zaproszeniu bądź hasło jest nieprawidłowe.</b>
                        <br/><br/>
                        Sprawdź wprowadzone dane i spróbuj ponownie. Jeżeli komunikat nadal się pojawia, skorzystaj z pomocy poniżej.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose3}>Pomoc</Button>
                    <Button onClick={handleClose2}>Zamknij</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            </Container>
    )
}

export {LoginPage};